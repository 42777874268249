<template>
  <Content
    :current-id="currentId" 
    :init='{ dispatchAction: "pos", currentId: currentId }'
    :submenu='{ state: true, name: "pos", currentData: "currentPOS"}'
    :toolbar='{ init: true, name: "pos", dispatchAction: "POINT_OF_SALE", currentData: "currentPOS", currentId: currentId}' 
    :key="routeChange"
  />
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { onBeforeRouteUpdate } from "vue-router"
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useContentStore } from "@/stores/content";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Content from '@/components/Content.vue';

export default defineComponent({
  components: {
    Content,
  },
  setup(props, {emit}) {
    const route = useRoute();
    const { t } = useI18n();
    const contentStore = useContentStore();
    const routeChange = ref(0);

    const currentId = ref(route.params.posId);

    onMounted(() => {
      MenuComponent.reinitialization();
      contentStore.setBreadcrumbTitle(t('titles.POS') + ' - ' + currentId.value);
    })

    onBeforeRouteUpdate((to) => {
      currentId.value = to.params.posId;
      routeChange.value += 1
     // contentStore.setBreadcrumbTitle(t('titles.Product') + ' - ' + currentId.value);
    })

    return {
      currentId,
      routeChange
    }
  }
})
</script>

